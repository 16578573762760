export const newletter = [
  {
    description: "PAC HOLDINGS NEWSLETTER",
    volume: 7,
    date: "26 January 2023",
  },
  {
    description: "PAC HOLDINGS NEWSLETTER",
    volume: 6,
    date: "March 2021",
  },
  {
    description: "PAC HOLDINGS NEWSLETTER",
    volume: 5,
    date: "December 2020",
  },
  {
    description: "PAC HOLDINGS NEWSLETTER",
    volume: 4,
    date: "June 2020",
  },
  {
    description: "PAC HOLDINGS NEWSLETTER",
    volume: 3,
    date: "December 2019",
  },
  {
    description: "PAC HOLDINGS NEWSLETTER",
    volume: 2,
    date: "August 2019",
  },
  {
    description: "PAC HOLDINGS NEWSLETTER",
    volume: 1,
    date: "December 2018",
  },
];
